<script>
import { mapGetters } from 'vuex';
import chains from '@/constants/chains';
import dayjs from 'dayjs';

export default {
  name: 'Table',
  props: {
    content: Array,
    title: String,
    header: Array,
    claim: Boolean,
  },
  data() {
    return {
      chainsList: chains,
      open: false,
      filter: null,
      page: 1,
      pageSize: 20,
      logos: {
        1: 'eth-logo.svg',
        3: 'eth-logo.svg',
        4: 'eth-logo.svg',
        56: 'bsc-logo.svg',
        97: 'bsc-logo.svg',
        43113: 'avax-logo.svg',
        43114: 'avax-logo.svg',
      },
    };
  },

  computed: {
    ...mapGetters({
      chains: 'Bridge/chains',
    }),

    initData() {
      const colWidth = 100 / this.header.length;
      return { headers: this.header, colWidth };
    },
    chunkData() {
      const chunkSize = this.open ? this.pageSize : 2;
      let data = this.content;
      if (this.filter) {
        data = data.filter((el) => el.ticker === this.filter);
      }
      return this.chunk(data, chunkSize);
    },

    filteredData() {
      return this.chunkData[this.page - 1];
    },
  },
  methods: {
    dayjs,
    hasProp(obj, prop) {
      if (typeof obj === 'object') {
        return prop in obj;
      }
      return false;
    },
    chunk(data, chunkSize) {
      const R = [];
      for (let i = 0, len = data.length; i < len; i += chunkSize) {
        R.push(data.slice(i, i + chunkSize));
      }
      return R;
    },

    getHash(row) {
      return this.claim ? row.mintedTxHash : row.burnedTxHash;
    },

    explorerLink(row) {
      const chain = this.claim ? this.getChain(row.networkTo) : this.getChain(row.networkFrom);
      return chain?.explorer ?? '';
    },
    getChain(name) {
      const data = Object.entries(this.chains);
      const id = data.find((el) => el[1] === name)[0];
      return this.chainsList.find((el) => el.chainId === Number(id));
    },
    chainName(name) {
      const chain = this.getChain(name);
      return chain?.shortName ?? '';
    },
    chainIcon(name) {
      const chain = this.getChain(name);
      return this.logos[chain.chainId];
    },
  },
};
</script>

<template>
    <div
      class="table main-block"
      :style="{ '--col-width': `${initData.colWidth}%` }"
    >
        <div class="table__title">{{ title }}</div>
        <div class="table__row table__row--header">
          <div class="table__col">
            <v-menu offset-y attach class="table__select">
              <template v-slot:activator="{ attrs, on }">
                <div v-bind="attrs" v-on="on">
                  Token {{ filter }}
                  <v-icon>mdi-chevron-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item @click="filter = ''"> All </v-list-item>
                <v-list-item @click="filter = 'MILK2'"> MILK2 </v-list-item>
                <v-list-item @click="filter = 'SHAKE'"> SHAKE </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="table__col">Id</div>
          <div class="table__col" v-for="(header, i) in initData.headers" :key="i">
              {{ header }}
          </div>
        </div>
        <div class="table__row" v-for="(row, i) in filteredData" :key="i">
              <div class="table__col">{{ row.ticker }}</div>
              <div class="table__col"> {{ row.orderId }} </div>
              <div class="table__col"> {{ row.amount | amount(2) }} </div>
              <div class="table__col">
                {{ dayjs((claim ? row.updatedAt : row.createdAt) * 1000).format("h:mm:ss A") }}
                <div class="table__date">
                  {{ dayjs((claim ? row.updatedAt : row.createdAt) * 1000).format("DD.MM.YYYY") }}
                </div>
              </div>
              <div class="table__col">
                <div v-if="!claim">
                  from <span>{{ chainName(row.networkFrom) }}</span>
                  <img
                    class="table__network-icon"
                    :src="require(`@/assets/img/bridge/${chainIcon(row.networkFrom)}`)"
                    alt="From network icon">
                </div>
                <div>
                  {{ claim ? '' : 'to' }} <span>{{ chainName(row.networkTo) }}</span>
                  <img
                    class="table__network-icon"
                    :src="require(`@/assets/img/bridge/${chainIcon(row.networkTo)}`)"
                    alt="To network icon">
                </div>
              </div>
              <div class="table__col">
                <a
                  :href="`${explorerLink(row)}/tx/${getHash(row)}`"
                  target="_blank"
                  v-if="getHash(row)"
                >
                  {{ getHash(row).slice(0, 4) }}...{{ getHash(row).slice(-4) }}
                </a>
              </div>
            </div>
        <v-pagination
          v-if="open && chunkData.length > 1"
          class="table__pagination"
          v-model="page"
          :length="chunkData.length"
          circle
        ></v-pagination>
        <div
          v-if="content.length > 2"
          class="table__button"
          :class="{'table__button-rotate': open}"
          @click="open = !open"
        ></div>
    </div>
</template>

<style lang="scss">
.table {
  display: block;
  border-radius: 30px;
  padding: 3rem 4rem;
  margin-bottom: 3rem;
  &__network-icon{
    width: 3rem;
    height: 3rem;
    padding: 0.4rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: white;
    border-radius: 3rem;
    margin: 0.2rem 0.4rem;
    img{
      width: 100%;
    }
  }
  &__close &__row--header{
    border-bottom: none;
  }
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    line-height: 150%;
    margin-bottom: 6rem;
  }
  &__pagination{
    margin-top: 4rem;
    margin-bottom: 3rem;
    .v-pagination__item, .v-pagination__navigation{
      width: 3.6rem;
      min-width: 3.6rem;
      height: 3.6rem;
      font-size: 1.4rem;
    }
  }
  &__row {
    display: flex;
    border-bottom: 2px dashed rgba(160, 165, 213, 0.3);
    transition: 0.3s;

    &--header {
      padding-bottom: 2rem;
      border-bottom: 2px solid rgba(160, 165, 213, 0.2);
      .v-icon{
        color: #283287 !important;
        font-size: 3rem;
        position: relative;
        top: 0.1rem;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 2rem;
    line-height: 140%;
    color: #283287;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 1rem 0;
    &:not(:nth-child(2)){
      flex: var(--col-width);
    }
    &:nth-child(2){
      min-width: 6rem;
    }
    &:first-child{
      align-items: flex-start;
      padding-left: 3rem;
    }
    &:nth-child(5){
      align-items: flex-end;
      padding-right: 1rem;
    }
    a{
      color: #283287;
      text-decoration: none;
    }
    > span {
      display: block;

      &:last-of-type {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 155%;
        letter-spacing: 0.03em;
        color: #7478A0;
      }
    }

    > div {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      span {
        font-weight: 600;
      }
    }
  }

  &__button {
    width: 2rem;
    height: 3.2rem;
    display: block;
    margin: 2rem auto 0;
    padding: 2rem 0;
    background-image: url('~@/assets/img/bridge/Table.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    &-rotate{
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
    }
  }

  &__date{
    font-weight: 500;
    color: #7478A0;
  }
  &__select{
    .v-menu__content{
      border-radius: 1rem;
      box-shadow: 0 0.4rem 8px 0px rgb(0 0 0 / 25%);
    }
    .v-list-item{
      min-height: 38px;
    }
    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
      color: #283287 !important;
    }
  }

  @keyframes buttonAnim {
    from {
      background-position: center 0;
    }
    to {
      background-position: center -43px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 2rem 0;

    &__title {
      margin-bottom: 2rem;
    }

    &__col {
      font-size: 1rem;

      img {
        display: none;
      }
    }
  }

  body.binance &{
    *{
      color: white !important;
    }
    &__pagination{
      .v-pagination__item, .v-pagination__navigation{
        background-color: #171721;
        box-shadow: 0px 1px 7px 1px rgb(255 249 249 / 20%);
        &--active.primary{
          background: #F9CB38 !important;
          color: black !important;
        }
      }
    }
    &__select{
      .v-menu__content{
        box-shadow: 0 0.4rem 8px 0px rgb(88 88 88 / 45%);
      }
      .v-list{
        background: #1c1c23;
      }
      .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
        color: #fff !important;
      }
    }
  }
  body.avalanche &{
    *{
      color: black !important;
    }
    &__select{
      .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
        color: #000 !important;
      }
    }
    &__pagination{
      .v-pagination__item--active.primary{
        background: #FC7172 !important;
        color: white !important;
      }
    }
  }
}
</style>

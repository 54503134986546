<script>
import Table from '@/components/Bridge/Table';
import BetaVersion from '@/components/Bridge/BetaVersion';

export default {
  name: 'Explorer',
  components: {
    BetaVersion,
    Table,
  },
  data() {
    return {
      data: [],
      sentHeader: ['Sent Sum', 'Time/Date', 'From to', 'Explorer link'],
      claimHeader: ['Claimed SUM', 'Time/Date', 'Claimed on', 'Explorer link'],
    };
  },

  computed: {
    claimedOrders() {
      return this.data.filter((el) => el.status === 'COMPLETED')
        .sort((a, b) => (b.updatedAt - a.updatedAt));
    },
  },

  async mounted() {
    this.data = await this.$store.dispatch('Bridge/ordersForExplorer');
    this.data = this.data.sort((a, b) => (b.createdAt - a.createdAt));
  },
};
</script>

<template>
  <div class="explorer">
    <BetaVersion style="margin-bottom: 4rem;"/>
    <div class="explorer__banner">
      <img src="@/assets/img/banners/explorer.png" alt="Explorer image">
    </div>
    <div class="explorer__tables">
      <Table :claim="false" :header="sentHeader" :content="data" title="Sent" />
      <Table :claim="true" :header="claimHeader" :content="claimedOrders" title="Claimed" />
    </div>
  </div>
</template>

<style lang="scss">
.explorer {
  width: 130.4rem;
  margin: 0 auto;
  padding-top: 5rem;
  &__tables{
    margin-bottom: 10rem;
  }
  &__banner {
    margin-bottom: 4rem;
    img {
      box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
      width: 100%;
      border-radius: 3.9rem;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    width: calc(100% - 2.8rem);
    padding-top: 2rem;
    &__banner{
      img{
        border-radius: 1rem;
      }
    }
  }
}
</style>
